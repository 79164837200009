import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Title from '../Title'
import Intro from './Index/Intro'
import { useI18n } from '../I18n'

import Search from './Index/Search'
import Text from './Index/Text'
// import SearchButtons from './Index/SearchButtons'
import Free from './Index/Free'

import pages from '../Pages.module.css'
import styles from './Index.module.css'

var pageId = (window.location + "").split("/").pop();

IndexForm.propTypes = {
  gateway: PropTypes.string,
  gateway_biblioteka: PropTypes.string,
  gateway_lingvist: PropTypes.string,
  gateway_obuchenie: PropTypes.string,
  gateway_obshhezhitie: PropTypes.string,
  gateway_orgvznosy: PropTypes.string,
  gateway_publikacii: PropTypes.string,
  gateway_recenzirovanie: PropTypes.string,
  gateway_tipografiya: PropTypes.string,
  gateway_ekspertiza: PropTypes.string,

  return_url: PropTypes.string,
  locale: PropTypes.string
}


export default function IndexForm({ gateway, gateway_biblioteka, gateway_lingvist, gateway_obuchenie, gateway_obshhezhitie, gateway_orgvznosy, gateway_publikacii, gateway_recenzirovanie, gateway_tipografiya, gateway_ekspertiza, return_url: returnUrl, locale, match, location }) {
  var pageId = (window.location + "").split("/").pop();


  // const [type, setType] = useState('free')

  // const isFree = () => type === 'free'
  // const isContract = () => type === 'contract'

  const I18n = useI18n(locale)
  // const buttonType = (e) => {
  //   console.log(e);
  //   console.log("click");

  // }

  return (
    <div className={pages.beta}>
      <Title
        beta
        pay_h1={I18n.t('pay.index.title')}
      />

      {/* <h3>{locale}</h3> */}


      {/* <div className={pages.container}>
        <div className={styles.tabs}>
          <ul>
            <li className={classNames(styles.tab, { [styles.active]: type === 'free' })} onClick={() => setType('free')}>
              В свободной форме
            </li>

            <li className={classNames(styles.tab, { [styles.active]: type === 'contract' })} onClick={() => setType('contract')}>
              По  номеру договора
            </li>
          </ul>
        </div>
      </div> */}

      <div className={styles.search}>
        <div className={pages.container}>
          {/* {isFree() &&
            <Free gateway={gateway} returnUrl={returnUrl} />
          } */}

          <Free gateway={gateway} gateway_biblioteka={gateway_biblioteka} gateway_lingvist={gateway_lingvist} gateway_obuchenie={gateway_obuchenie} gateway_obshhezhitie={gateway_obshhezhitie} gateway_orgvznosy={gateway_orgvznosy} gateway_publikacii={gateway_publikacii} gateway_recenzirovanie={gateway_recenzirovanie} gateway_tipografiya={gateway_tipografiya} gateway_ekspertiza={gateway_ekspertiza} returnUrl={returnUrl} pageId={pageId} />

          {/* {isContract() &&
            <div>
              Оплата по номеру договора временно недоступна. В ближайшее время этот функционал будет доделан.
            </div>
          } */}
        </div>
      </div>

      <div className={pages.container}>
        {/* <div className={styles.root}>
          QWE
        </div> */}
        <div className={styles.text}>
          <Text />
        </div>
      </div>
    </div>

  )
}
