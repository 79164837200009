import React from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'
import classNames from 'classnames'

import { useForm } from '../../Form'

import styles from './Free.module.css'
import form from '../../FormStatic.module.css'
import buttons from '../../Buttons.module.css'

Free.propTypes = {
  gateway: PropTypes.string,
  gateway_biblioteka: PropTypes.string,
  gateway_lingvist: PropTypes.string,
  gateway_obuchenie: PropTypes.string,
  gateway_obshhezhitie: PropTypes.string,
  gateway_orgvznosy: PropTypes.string,
  gateway_publikacii: PropTypes.string,
  gateway_recenzirovanie: PropTypes.string,
  gateway_tipografiya: PropTypes.string,
  gateway_ekspertiza: PropTypes.string,
  returnUrl: PropTypes.string
}

export default function Free({ gateway, gateway_biblioteka, gateway_lingvist, gateway_obuchenie, gateway_obshhezhitie, gateway_orgvznosy, gateway_publikacii, gateway_recenzirovanie, gateway_tipografiya, gateway_ekspertiza, returnUrl, pageId }) {
  const pageKeys = [
    { name: "", key: "key_obuchenie", gateway: "gateway_obuchenie" },
    { name: "номер комнаты, общ", key: "key_obshhezhitie", gateway: "gateway_obshhezhitie" },
    { name: "Публикации", key: "key_publikacii", gateway: "gateway_publikacii" },
    { name: "Оргвзносы", key: "key_orgvznosy", gateway: "gateway_orgvznosy" },
    { name: "СОЛ", key: "key_lingvist", gateway: "gateway_lingvist" },
    { name: "Рецензирование", key: "key_recenzirovanie", gateway: "gateway_recenzirovanie" },
    { name: "Экспертиза", key: "key", gateway: "gateway_ekspertiza" },
    { name: "Библ.", key: "key_biblioteka", gateway: "gateway_biblioteka" },
    { name: "УММ", key: "key_tipografiya", gateway: "gateway_tipografiya" }
  ]




  const {
    values,
    handleInputChange
  } = useForm({ contract: '', name: '', phone: '', amount: '', paingName: '', roomNumber: '' })

  let canSubmit = () => {
    return values.contract !== '' && values.name !== '' && values.phone !== '' && values.amount !== '' && values.paingName != ''
  }


  let contractValues = values.contract + " " + pageKeys[pageId - 1].name;



  let payment_gateway = ""

  if (pageId == 1) {
    payment_gateway = gateway_obuchenie;
    contractValues = values.contract;

  }

  if (pageId == 2) {
    payment_gateway = gateway_obshhezhitie;
    contractValues = values.contract + " " + values.roomNumber;

  }

  if (pageId == 3) {
    payment_gateway = gateway_publikacii;
  }

  if (pageId == 4) {
    payment_gateway = gateway_orgvznosy;
  }

  if (pageId == 5) {
    payment_gateway = gateway_lingvist;
  }

  if (pageId == 6) {
    payment_gateway = gateway_recenzirovanie;
  }

  if (pageId == 7) {
    payment_gateway = gateway_ekspertiza;
  }

  if (pageId == 8) {
    payment_gateway = gateway_biblioteka;
  }

  if (pageId == 9) {
    payment_gateway = gateway_tipografiya;
  }



  let extBlock = <div className={form.input}>
    <label>
      <div className={form.label}>
        Фамилия, имя и отчество плательщика
      </div>

      <input
        type="text"
        value={values.paingName}
        name="paingName"
        onChange={handleInputChange}


      />
    </label>
  </div>


  let contractBlock = contractBlock = <div className={form.input}>
    <label>
      <div className={form.label}>
        Номер договора
      </div>

      <input
        type="text"
        value={values.contract}
        name="contract"
        onChange={handleInputChange}
      />

    </label>
  </div>


  if (pageId == 1) {

    contractBlock = <div className={form.input}>
      <label>
        <div className={form.label}>
          Номер договора
        </div>

        <input
          type="text"
          value={values.contract}
          name="contract"
          onChange={handleInputChange}
        />

      </label>
    </div>

  }


  if (pageId == 2) {
    canSubmit = () => {
      return values.contract !== '' && values.name !== '' && values.phone !== '' && values.amount !== '' && values.roomNumber != ''
    }

    extBlock = <div className={form.input}>
      <label>
        <div className={form.label}>
          Номер комнаты, месяц и год проживания
        </div>

        <input
          type="text"
          value={values.roomNumber}
          name="roomNumber"
          onChange={handleInputChange}

        />
      </label>
    </div>


    contractBlock = <div className={form.input}>
      <label>
        <div className={form.label}>
          Номер договора
        </div>

        <input
          type="text"
          value={values.contract}
          name="contract"
          onChange={handleInputChange}
        />

      </label>
    </div>

  }



  const handleSubmit = e => {
    if (canSubmit()) {

    } else {
      e.preventDefault()
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.intro}>
        <p>
          Для оплаты заполните необходимые поля и нажмите на кнопку «Оплатить», после этого вы будете перенаправлены на защищенную платежную страницу «Газпромбанк» (Акционерное общество), где будет необходимо ввести данные вашей пластиковой карты.
        </p>

        <p>
          В случае успешной оплаты вы получите от сайта уведомление о том, что оплата проведена.
        </p>
      </div>


      <div className={styles.form}>
        <div className={form.item}>
          <div className={form.input}>
            <label>
              <div className={form.label}>
                Фамилия, имя и отчество
              </div>

              <input
                type="text"
                value={values.name}
                name="name"
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>

        <div className={form.item}>
          <div className={form.input}>
            <label>
              <div className={form.label}>
                Телефон
              </div>

              <input
                type="text"
                value={values.phone}
                name="phone"
                onChange={handleInputChange}

              />
            </label>
          </div>
        </div>

        <div className={form.item}>
          {extBlock}
        </div>

        <div className={form.item}>
          {contractBlock}
        </div>

        <div className={form.item}>
          <div className={form.input}>
            <label>
              <div className={form.label}>
                Сумма платежа
              </div>

              <input
                type="text"
                value={values.amount}
                name="amount"
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>
      </div>

      <form method="post" action={payment_gateway} onSubmit={handleSubmit}>
        <input type="hidden" name="sum" value={values.amount} />
        <input type="hidden" name="orderid" value={contractValues} />
        <input type="hidden" name="clientid" value={values.name} />
        <input type="hidden" name="paingclientid" value={values.paingName} />
        <input type="hidden" name="roomNumber" value={values.roomNumber} />
        <input type="hidden" name="client_phone" value={values.phone} />
        <input type="hidden" name="user_result_callback" value={returnUrl} />
        <input type="hidden" name="payment_key" value={pageKeys[pageId - 1].key} />


        <input
          type="submit"
          value="Оплатить"
          className={classNames(buttons.main, buttons.big)}
          disabled={!canSubmit()}
        />
      </form>
    </div>
  )
}
