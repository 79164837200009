import React from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'
import classNames from 'classnames'

// import { useForm } from '../../Form'

// import styles from './Free.module.css'
// import form from '../../FormStatic.module.css'
import buttons from '../../Buttons.module.css'
import search_buttons from './SearchButtons.module.css'



SearchButtons.propTypes = {
  gateway: PropTypes.string,
  returnUrl: PropTypes.string
}

export default function SearchButtons({ gateway, returnUrl }, props) {
  // const {
  //   values,
  //   handleInputChange
  // } = useForm({ contract: '', name: '', phone: '', amount: '', paingName: '' })

  // const canSubmit = () => {
  //   return values.contract !== '' && values.name !== '' && values.phone !== '' && values.amount !== '' && values.paingName != ''
  // }

  // const handleSubmit = e => {
  //   if (canSubmit()) {

  //   } else {
  //     e.preventDefault()
  //   }
  // }

  const searchButtons = [
    { name: "НГЛУ. Обучение", key: "learn" },
    { name: "НГЛУ. Общежитие", key: "residence" },
    { name: "НГЛУ. Публикации", key: "public" },
    { name: "НГЛУ. Оргвзносы", key: "org" },
    { name: "СОЛ. Лингвист", key: "ling" },
    { name: "НГЛУ. Рецензирование", key: "review" },
    { name: "НГЛУ. Экспертиза", key: "ekspertisa" },
    { name: "НГЛУ. Библиотека", key: "library" },
    { name: "НГЛУ. Типография", key: "typography" }
  ]

  return (

    <div className={search_buttons.root}>
      <div className={search_buttons.intro}>
        <p>
          Для оплаты выберите назначение платежа СТРОГО согласно вашему договору.
        </p>

      </div>

      <div className={search_buttons.intro}>
        <p>
          Назначение платежа.
        </p>

      </div>



      <div className={classNames(search_buttons.search_block)}>




        {searchButtons.map((button, index) => (
          <a href={"/payform/" + (index + 1)} className={classNames(search_buttons.main, buttons.big)} key={index} onClick={props.handleChange}>{button.name}</a>
        ))}




      </div>


    </div>
  )
}
