import React from 'react'
// import PropTypes sfrom 'prop-types'

import styles from './Text.module.css'

export default function Text () {
  return (
    <div>
      <p>
        Начните заполнение анкеты. Предоставляя свои данные, вы подтверждаете свое согласие на обработку ваших персональных данных, как это предусмотрено Федеральным законом №152-ФЗ «О персональных данных». Анкета состоит из 10 шагов, на которых нужно заполнить следующую информацию:
      </p>

      <ul className={styles.ul}>
        <li>
          ваши личные данные;
        </li>

        <li>
          сведения о документе, удостоверяющем личность;
        </li>

        <li>
          информация о родителях;
        </li>

        <li>
          адреса регистрации и проживания;
        </li>

        <li>
          информация об оконченном учебном заведении;
        </li>

        <li>
          информация об имеющемся образовании;
        </li>

        <li>
          информация о баллах ЕГЭ и индивидуальных достижениях;
        </li>

        <li>
          информация о направлениях подготовки и формах обучения.
        </li>
      </ul>

      <p>
        На последнем шаге вы сможете проверить все данные перед отправкой их на проверку. Обращаем ваше внимание, что после заполнения всех форм и отправки документов на проверку внести изменения через сайт будет невозможно. Поэтому прежде чем приступить к подаче заявления, убедитесь, пожалуйста, что вы собрали все необходимые документы и хорошо продумали, на какие направления подготовки и формы обучения вы хотите поступать. В названиях файлов, которые вы будете прикреплять, укажите ваши фамилию, инициалы и вид документа, например: Светлова_АН_паспорт
      </p>

      <p>
        Если до окончания приема документов вы решите что-то изменить (добавить или поменять направление, добавить документы и т.п.), вы сможете сделать это, написав нам по электронной почте <a href="mailto:lunnpriem2020@gmail.com">lunnpriem2020@gmail.com</a>.
      </p>
    </div>
  )
}
